exports.onRouteUpdate = ({ location }) => {
  if (location.search.includes('?video')) {
    setTimeout(function(){ 
      document.getElementById('openModal').click();
    }, 50);
  } else if (location.search.includes('?listings')) {
    setTimeout(function(){ 
      let elmnt = document.getElementById('listings');
      elmnt.scrollIntoView({
          behavior: 'smooth'
      });
    }, 50);
  } 
}
